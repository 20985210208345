<template>
  <div class="container">
    <div class="block">
      <div class="blockstyle">
        <div class="toptitle">
          <img src="@img/lcicon.png" alt="" class="icon" />
          <span>个人认证流程</span>
        </div>
        <div class="main textcenter">
          <img src="@img/renzhenlc.png" alt="" style="margin: 30px 0 10px" />
        </div>
      </div>
    </div>
    <div class="block">
      <div class="blockstyle">
        <div class="toptitle">
          <img src="@img/zlicon.png" alt="" class="icon" />
          <span>资料填写</span>
        </div>
        <div class="main2">
          <el-form
            ref="form"
            :model="form"
            label-width="110px"
            label-position="left"
          
          >
            <el-form-item label="姓名：">
              <el-input v-model="form.realname"></el-input>
            </el-form-item>
            <el-form-item label="性别：">
              <el-radio-group v-model="form.sex">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="联系电话：" prop="phone">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item prop="phone_code" label="验证码：">
              <div class="flex">
                <el-input
                  v-model="form.phone_code"
                  placeholder="请输入验证码"
                  class="input-y"
                  clearable
                >
                
                </el-input>
                <div class="getcode">
                  <el-button
                    type="primary"
                    class="getcode"
                    :class="{ disab: !timeout }"
                  >
                    <span v-if="!timeout">{{ time }}秒后重新获取</span>
                    <span v-else @click="getcode">获取验证码</span>
                  </el-button>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="地区：">
              <get-area @input="setarea"></get-area>
            </el-form-item>

            <el-form-item label="详细地址：">
              <el-input v-model="form.address"></el-input>
            </el-form-item>
            <el-form-item label="技能类型：">
              <el-input v-model="form.technical_types"></el-input>
              <!--    <el-select v-model="form.technical_types" placeholder="请选择业务方向">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="专业特长：">
              <el-input
                type="textarea"
                v-model="form.tc"
                rows="5"
                placeholder="请输入专业特长，例如工作年限、过往经验等"
              ></el-input>
            </el-form-item>

            <el-form-item label="技能证书：">
              <upload-img @input="getimg"></upload-img>
            </el-form-item>

            <div class="flex flexcenter">
              <el-button type="primary" @click="onSubmit" class="tjrz">提交认证</el-button>
            </div>
            <div class="flex bottom1 justify-center" style="margin-top: 13px">
              <el-checkbox v-model="checked" class="remember"
                >我已阅读并同意<span class="blue"  @click="openpage('aboutus', 'rzxy')">《认证协议》</span
                >。</el-checkbox
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getArea from "@c/common/area.vue";
import uploadImg from "@c/common/uploadimg.vue";
import { mapState, mapMutations } from "vuex";
const defaulttime = 60;
export default {
  data() {
    return {
      form: {
        realname: "",
        sex: 1,
        phone: "",
        phone_code: "",
        ssc: "",
        address: "",
        technical_types: "",
        technical_certimages: [],
        tc: "",
      },
        checked: false,
     
      time: defaulttime,
      timeout: true,
      timeoutobj: null,
    };
  },
  components: { getArea, uploadImg },
  computed: {
    ...mapState(["isLogin", "userInfo"]),
  },
  mounted() {
    this.$nextTick(()=>{
       this.scrollToTop();
    })
  
  },
  methods: {
    ...mapMutations([
      "setLogin",
      "setUserInfo",
      "setLoginDialog",
      "setRegistDialog",
    ]),
     scrollToTop(){
         window.scroll({ top: 0, left: 0});
     },
    setarea(str) {
      this.form.ssc = str;
    },
    getimg(str) {
      this.form.technical_certimages = str.split(",");
    },
    countdown() {
      this.timeoutobj = setTimeout(() => {
        this.time = this.time - 1;
        if (this.time <= 0) {
          clearTimeout(this.timeoutobj);
          this.timeout = true;
        } else {
          this.countdown();
        }
      }, 1000);
    },
    getcode() {
      var phone = this.form.phone;
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        this.$message.error({ message: "手机号不正确！!" });
      } else {
        this.$http
          .sendsms({
            mobile: phone,
            event: "auth_person",
          })
          .then((res) => {
            this.timeout = false;
            this.time = defaulttime;
            this.countdown();
          });
      }
    },
     openpage(path, id) {
      this.$router.push({
        path,
        query: {
          id,
        },
      });
    },
    onSubmit() {
      
      if (!this.checked) {
        this.$message.error("请查看并勾选相关协议！");
        return;
      }

      this.$http.authperson(this.form).then((res) => {
        if (res.code ==0) {
          if(res.msg.indexOf("必填项")>0){
            this.$message.error("全部必填，您还有信息没有填哦！");
          }else{
             this.$message.error({ message: res.msg });
          }
         
        } else if (res.code == 1) {
          this.$message.success({ message:  res.msg });
         this.openpage("/index");
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/styles/mixin.scss";
.container {
  margin-bottom: 20px;
}
.getcode {
  // width: 180px;
  padding-left: 20px;
}
.tjrz {
  font-size: 16px;
  width: 200px;
  margin-top: 30px;
}
.disab {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
.block {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.blockstyle {
  flex: 1;
  padding: 10px 15px;
  border-radius: 2px;
  background-color: #fff;
  .toptitle {
    position: relative;
    width: 100%;
    padding-bottom: 10px;
    font-weight: bold;
    &:after {
      content: "";
      position: absolute;
      left: -14px;
      right: -15px;
      bottom: 0;
      height: 1px;
      background-color: $color-theme;
    }
    .more {
      float: right;
      font-size: 18px;
      display: flex;
      align-items: center;
      transition: all 1s linear;
      cursor: pointer;
      &:hover {
        color: $color-theme;
      }
      img {
        margin-left: 10px;
        margin-top: 2px;
      }
    }
    .icon {
      margin-right: 10px;
      transform: translateY(3px);
    }
  }
}
.main2 {
  padding: 30px 140px;
}

/deep/.el-radio__label {
  font-size: 18px;
}

.el-form {
  /deep/.el-form-item__label {
    font-size: 18px;
    color: #333;
  }
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 120px;
  height: 120px;
}
/deep/.el-upload--picture-card {
  box-sizing: border-box;
  width: 120px;
  height: 120px;
  cursor: pointer;

  vertical-align: top;
  border: none;
  background: none;
}
.addpic {
  width: 120px;
  height: 120px;
  background-color: #f1f1f1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  img {
    display: block;
  }
  .word {
    color: #999;
    font-size: 20px;
    line-height: 30px;
  }
}

.el-select {
  width: 100%;
}
</style>
